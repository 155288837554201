<template>
  <!-- 招商实施 -->
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="main-content"
  >
    <div
      class="iframe-content"
      v-if="hasPermission"
    >
      <!-- <div class="w-crumbs-href">
        <router-link
          tag="span"
          :to="{ path: '/' }"
        >工作台</router-link>
        <i class="el-icon-arrow-right"></i>
        <router-link
          tag="span"
          :to="{ path: '/implement' }"
        >招商实施分析</router-link>
        <i class="el-icon-arrow-right"></i>
        <span class="w-white">{{titleName}}</span>
      </div> -->
      <iframe
        :src="url"
        id="commandIframe"
        frameborder="0"
      ></iframe>
    </div>
    <div
      class="simpleInfoPanel"
      v-else
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/api'
export default {
  data () {
    return {
      hasPermission: true,
      loading: true,
      type: '',
      url: '',
      userAuthorities: [],
      urlList: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token'
    })
  },
  watch: {
    $route: function (oldVal, newVal) {
      this.type = this.$route.query.type
      this.init()
    }
  },
  mounted () {
    this.type = this.$route.query.type
    this.userAuthorities = this.user.confIds.filter(item => {
      return item.id === 139
    })
    if (this.userAuthorities.length) {
      this.axios.post(api.getKPurl).then(res => {
        this.urlList = res.data.data
        this.init()
      })
    } else {
      this.hasPermission = false
      this.loading = false
    }
    const iframe = document.getElementById('commandIframe')
    if (iframe.attachEvent) {
      iframe.attachEvent('onload', () => {
        // iframe加载完成后要进行的操作
        this.loading = false
      })
    } else {
      iframe.onload = () => {
        // iframe加载完成后要进行的操作
        this.loading = false
      }
    }
  },
  methods: {
    init () {
      for (const item of this.urlList) {
        if (this.type === item.name) {
          const arrURL = item.url.split('com/set/')
          this.url = this.$shulieDomain + arrURL[1] + '&token=' + this.token
        }
      }
      this.loading = true
      if (!this.userAuthorities.length) {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.main-content
  width calc(100% - 226px)
  margin-left 204px
  margin-top 10px
  height 800px
  margin-bottom 30px
  .iframe-content
    height 800px
    iframe
      width 100%
      height 100%
</style>
